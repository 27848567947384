/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Page
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-no-subscription',
  templateUrl: './no-subscription.component.html',
  styleUrls: ['./no-subscription.component.scss']
})
export class NoSubscriptionComponent implements OnInit {
  ref: any;
  errorText: any;
  errorType: number;

  constructor(
    private _activeRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this._activeRoute.queryParams.subscribe((param) => {
      this.ref = param['ref']
      this.errorText = param['error']
    })
  }

  redirect() {
    window.location.href = environment.myaccountDomain + "/product/" + environment.appSlug
  }
}
